import React from 'react'
import { requireAuthentication } from '../helpers/authentication'
import DashboardResults from './Results'
import DashboardControl from './Control'
import { Typography } from 'antd'
import { DEFAULT_TIME_AFTER_TEST } from './constants'
import { useLocalStorage } from 'react-use'
import { Flex } from 'antd'

import './styles.scss'
const { Title } = Typography

function ProductionLinePage() {
  const [searchTerm, setSearchTerm] = useLocalStorage(
    'production-line-search-query',
    { plant: null, testZones: [], timeAfterTest: DEFAULT_TIME_AFTER_TEST },
  )

  return (
    <div id="production-line-page">
      <div className="page-name">
        <Title level={3}>Production Line Dashboard</Title>
      </div>
      <DashboardControl searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Flex justify="center" vertical={false} id="production-line-results">
        <DashboardResults searchTerm={searchTerm} />
      </Flex>
    </div>
  )
}

export default requireAuthentication(ProductionLinePage)
