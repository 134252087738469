import React from 'react'
import { injectIntl } from 'react-intl'
import BaseDiagram from './BaseDiagram'
import { getDetailedPartitions } from './partitions'
import { useDetailDiagramGraph } from './hooks'
import { getNodeWidthByContent, calculateTextSize } from './nodes/dimensions'
import edgeRouter from './edges/detail/Router'
import nodeRouter from './nodes/detail/Router'
import {
  useDiagram,
  useDiagramDispatch,
} from '../FunctionalDiagrams/DiagramContext'
import { addPortIndex } from './indexedPorts'
import { addExtraInlinePorts } from './inlines'
import { Loadable } from '../components/Loading'

const DetailDiagram = ({
  intl,
  vin,
  fromComponent,
  toComponent,
  hideNotConnectedPins = true,
  hideInlineEdges = true,
  theme = null,
}) => {
  const diagram = useDiagram()
  const dispatch = useDiagramDispatch()
  const { isSuccess, isLoading, detailDiagramGraph } = useDetailDiagramGraph({
    vin,
    componentA: fromComponent,
    componentB: toComponent,
  })

  const onPortClick = ({ graph, port }) => {
    const {
      componentId,
      pin,
      parent,
      imageName,
      locationImageName,
      description: componentDescription,
      alias: componentAlias,
      category,
    } = graph.$id(port.id).data()

    const neighborIds = graph
      .$id(port.id)
      .openNeighborhood()
      .nodes()
      .filter(
        (element) => element.data('parent') !== parent || isJumper(element), // exclude inline sibling neighbor
      )
      .map((node) => node.data('componentId'))

    dispatch.detailDiagram.handlePinSelection({
      componentId,
      pin,
      ids: [port.id],
      neighborIds,
      imageName,
      locationImageName,
      componentDescription,
      componentAlias,
      category,
    })
  }

  const onNodeClick = ({ graph, node }) => {
    const category = graph.$id(node.id).data('category')
    const alias = graph.$id(node.id).data('alias')
    const description = graph.$id(node.id).data('description')

    // Currently, only splices are supported.
    if (category !== 'splice') return

    const retNode = {
      ids: [node.id],
      componentId: node.id,
      alias,
      description,
      locationImageName: node.data.locationImage,
      category: category,
    }

    dispatch.detailDiagram.handleComponentSelection(retNode)
  }

  const onEdgeClick = ({ graph, edge }) => {
    const fromPort = graph.$id(edge.fromPort)
    const toPort = graph.$id(edge.toPort)
    const fromComponent = fromPort.data('componentId')
    const fromComponentImageName = fromPort.data('imageName')
    const toComponentImageName = toPort.data('imageName')
    const toComponent = toPort.data('componentId')
    const fromComponentCategory =
      fromPort.data('category') === 'inline-prime'
        ? 'inline'
        : fromPort.data('category')
    const toComponentCategory =
      toPort.data('category') === 'inline-prime'
        ? 'inline'
        : toPort.data('category')

    const fromPin = fromPort.data('pin')
    const toPin = toPort.data('pin')

    const fromLocationImageName = fromPort.data('locationImageName')
    const toLocationImageName = toPort.data('locationImageName')

    const fromComponentDescription = fromPort.data('description')
    const toComponentDescription = toPort.data('description')

    const fromComponentAlias = fromPort.data('alias')
    const toComponentAlias = toPort.data('alias')

    const { harness, harness_description, colors, circuit, description } =
      edge.data

    dispatch.detailDiagram.handleCircuitSelection({
      ids: [edge.id],
      fromPin,
      toPin,
      fromComponent,
      fromComponentCategory,
      fromComponentImageName,
      toComponent,
      toComponentImageName,
      toComponentCategory,
      harness,
      harness_description,
      colors,
      circuit,
      circuit_description: description,
      fromLocationImageName,
      toLocationImageName,
      fromComponentDescription,
      toComponentDescription,
      toComponentAlias,
      fromComponentAlias,
    })
  }

  const getNodeDimensions = ({ parent, ports }) => {
    const defaultHeight = 50

    const categoryDimensions = new Map(
      Object.entries({
        splice: { height: 50, width: 50 },
      }),
    )

    const { width, height } = categoryDimensions.get(
      parent.data('category'),
    ) || {
      width: getNodeWidthByContent({ parent, ports }),
      height: defaultHeight,
    }

    return {
      width,
      height,
    }
  }

  const getPortConstraints = ({ node }) => {
    return node.data('category') === 'inline' ? 'FIXED_ORDER' : 'FREE'
  }

  const getPortSide = ({ port, partitionMapping }) => {
    if (port.data('category') === 'inline') {
      if (isJumper(port)) {
        // Inline can have a jumper. Jumper is a connection between pins of the inline(s).
        // Get the adjacent inline port of the jumper's port.
        const adjacentPort = port
          .connectedEdges()
          .filter((edge) => edge.data('category') === 'inline')
          .connectedNodes()
          .filter((node) => node.id() !== port.id())[0]

        const adjacentPortSide = getNonJumperPortSide({
          port: adjacentPort,
          partitionMapping,
        })
        return adjacentPortSide === 'NORTH' ? 'SOUTH' : 'NORTH'
      } else {
        const allSiblings = port.parent().children()
        const primeSiblings = allSiblings.filter(
          (node) => node.data('category') === 'inline-prime',
        )
        const sides = primeSiblings.map((port) =>
          getNonJumperPortSide({
            port,
            partitionMapping,
          }),
        )
        const frequency = getFrequency(sides)
        const side = mostFrequent(frequency)
        if (port.data('category') === 'inline-prime') {
          return side
        }
        return side === 'NORTH' ? 'SOUTH' : 'NORTH'
      }
    }

    return getNonJumperPortSide({ port, partitionMapping })
  }

  const getFrequency = (arr) => {
    const counts = arr.reduce((acc, num) => {
      acc[num] = (acc[num] || 0) + 1
      return acc
    }, {})
    return counts
  }
  const mostFrequent = (frequency) => {
    return Object.keys(frequency).reduce((a, b) =>
      frequency[a] > frequency[b] ? a : b,
    )
  }

  const getPortNeiborhood = (port) => {
    return port
      .neighborhood()
      .nodes()
      .filter((pin) => port.id() !== pin.id())
      .filter((pin) => port.parent().id() !== pin.parent().id())
  }

  const getNonJumperPortSide = ({ port, partitionMapping }) => {
    // Assume just one neighbor is present (pin to pin connection)

    const portNeighborhood = getPortNeiborhood(port)

    const nodePartition = partitionMapping[port.parent().id()]

    const neighborPartition =
      partitionMapping[portNeighborhood[0].parent().id()]

    return nodePartition > neighborPartition ? 'NORTH' : 'SOUTH'
  }

  const isJumper = (port) => {
    const allNodePorts = port.parent().children()

    return (
      allNodePorts
        .nodes()
        .edgesWith(allNodePorts)
        .filter((edge) => edge.data('category') !== 'inline')
        .filter(
          (edge) =>
            edge.source().id() == port.id() || edge.target().id() == port.id(),
        ).length === 1
    )
  }

  const prepareNodes = (graph) => {
    const partitionMapping = getDetailedPartitions(
      graph,
      fromComponent,
      toComponent,
    )
    const nodesWithPorts = graph
      .nodes()
      .filter((ele) => ele.isParent())
      .map((parent) => {
        const excludeSplices = (child) => child.data('category') !== 'splice'

        const ports = parent
          .children()
          .filter(excludeSplices)
          .filter((child) => {
            if (hideNotConnectedPins && child.neighborhood().length === 0) {
              return false
            }
            return true
          })
          .map((child) => {
            const { height, width } = calculateTextSize(child.data('label'))
            return {
              id: child.id(),
              side: getPortSide({ port: child, partitionMapping }),
              borderOffset: 10,
              width: Math.max(width, 20),
              height: Math.max(height, 20),
              label: child.data('label'),
              componentId: child.data('componentId'),
              category: child.data('category'),
            }
          })

        const { width, height } = getNodeDimensions({ parent, ports })
        const portsWithIndex = addPortIndex({ ports })
        return {
          text: parent.data('label'),
          id: parent.id(),
          width,
          height,
          layoutOptions: {
            'org.eclipse.elk.portConstraints': getPortConstraints({
              node: parent,
            }),
            'org.eclipse.elk.partitioning.partition':
              partitionMapping[parent.id()],
          },
          dataTestId: `detail-diagram-node-${parent.id()}`,
          data: {
            category: parent.data('category'),
            alias: parent.data('alias'),
            description: parent.data('description'),
            locationImage: parent.children()[0].data('locationImageName'),
          },
          ports: portsWithIndex,
        }
      })

    const nodesWithoutPorts = graph
      .nodes()
      .filter((ele) => ele.isChildless() && ele.isOrphan())
      .map((node) => {
        return {
          text: node.data('label'),
          id: node.id(),
          width: 50,
          height: 50,
          data: {
            category: node.data('category'),
          },
        }
      })
    return [...nodesWithPorts, ...nodesWithoutPorts]
  }

  const prepareSpliceEdge = (edge) => {
    // Initailly, splice parent has ports.
    // Connect edge directly to the parent instead of port.
    if (edge.source().data('category') === 'splice') {
      edge.move({
        source: edge.source().parent(),
      })
    }

    if (edge.target().data('category') === 'splice') {
      edge.move({
        target: edge.target().parent(),
      })
    }

    return edge
  }

  const prepareEdges = (graph) => {
    return graph
      .edges()
      .map(prepareSpliceEdge)
      .filter((edge) => {
        if (
          hideInlineEdges &&
          edge.data('category') === 'inline' &&
          edge.source().parent() === edge.target().parent()
        ) {
          return false
        }
        return true
      })
      .reduce((result, edge) => {
        const reaflowEdge = {
          id: edge.id(),
          data: {
            circuit: edge.data('circuit'),
            colors: edge.data('colors'),
            harness: edge.data('harness'),
            description: edge.data('description'),
            harness_description: edge.data('harness_description'),
            category: edge.data('category'),
          },
          'org.eclipse.elk.edge.thickness': 2,
          text: `${edge.data('colors').letter_code}`,
          dataTestId: [edge.source().id(), edge.target().id()]
            .sort()
            .join('::'),
        }
        if (edge.source().isOrphan()) {
          reaflowEdge.from = edge.data('source')
        } else {
          reaflowEdge.from = edge.source().parent().id()
          reaflowEdge.fromPort = edge.data('source')
        }

        if (edge.target().isOrphan()) {
          reaflowEdge.to = edge.data('target')
        } else {
          reaflowEdge.to = edge.target().parent().id()
          reaflowEdge.toPort = edge.data('target')
        }

        result.push(reaflowEdge)

        return result
      }, [])
  }

  return (
    <>
      <Loadable showText={false} isLoading={isLoading || !isSuccess}>
        <div className="detail-diagram">
          <BaseDiagram
            data={detailDiagramGraph}
            preprocess={(graph) => addExtraInlinePorts(graph)}
            prepareEdges={prepareEdges}
            prepareNodes={prepareNodes}
            edgeRouter={edgeRouter}
            nodeRouter={nodeRouter}
            onNodeClick={onNodeClick}
            onPortClick={onPortClick}
            onEdgeClick={onEdgeClick}
            layoutOptions={{
              'org.eclipse.elk.layered.nodePlacement.strategy':
                'NETWORK_SIMPLEX', // SIMPLE is a very simple and very fast node placement that centers all nodes vertically.
            }}
            selectionIds={diagram.detailDiagram.data.ids}
            refreshOnResize={true}
            diagramControlClassNames={'detail-diagram-zoom-control'}
            theme={theme}
          />
        </div>
      </Loadable>
    </>
  )
}

export default injectIntl(DetailDiagram)
