import { createContext, useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { useRecentSubsystems } from './hooks'

const DiagramContext = createContext(null)
const DiagramDispatchContext = createContext(null)

export function DiagramProvider({ children }) {
  const [state, dispatch] = useImmerReducer(diagramReducer, initialDiagram)

  return (
    <DiagramContext.Provider value={state}>
      <DiagramDispatchContext.Provider value={dispatch}>
        {children}
      </DiagramDispatchContext.Provider>
    </DiagramContext.Provider>
  )
}

export function useDiagram() {
  return useContext(DiagramContext)
}

export function useDiagramDispatch() {
  const { setRecentSubsystemId } = useRecentSubsystems()
  const dispatch = useContext(DiagramDispatchContext)
  const diagram = useDiagram()

  const handleTabChange = ({ tab }) =>
    dispatch({ type: 'block_diagram_tab_selected', tab })

  const handleDeviceSelection = ({
    componentId,
    ids,
    neighborIds,
    imageName,
    description,
    alias,
    locationImageName,
    category,
  }) => {
    if (diagram.detailDiagram.relatedSubsystemComponentID !== componentId) {
      handleRelatedSubsystemsButton({
        relatedSubsystemComponentID: null,
      })
    }
    handleTabChange({ tab: null })
    dispatch({
      type: 'block_diagram_device_selected',
      componentId,
      ids,
      neighborIds,
      imageName,
      description,
      alias,
      locationImageName,
      category,
    })
    toggleRightPanel({ rightPanelVisible: true })
  }
  const handleConnectorSelection = ({
    componentId,
    ids,
    imageName,
    description,
    color,
    alias,
    neighborIds,
    locationImageName,
    category,
  }) => {
    if (diagram.detailDiagram.relatedSubsystemComponentID !== componentId) {
      handleRelatedSubsystemsButton({
        relatedSubsystemComponentID: null,
      })
    }
    handleTabChange({ tab: null })
    dispatch({
      type: 'block_diagram_connector_selected',
      componentId,
      ids,
      imageName,
      locationImageName,
      description,
      color,
      alias,
      neighborIds,
      category,
    })
    toggleRightPanel({ rightPanelVisible: true })
  }
  const handleConnectionSelection = ({
    fromComponent,
    toComponent,
    fromComponentAlias,
    toComponentAlias,
    ids,
  }) => {
    handleRelatedSubsystemsButton({ relatedSubsystemComponentID: null })
    handleTabChange({ tab: null })
    dispatch({
      type: 'block_diagram_connection_selected',
      fromComponent,
      toComponent,
      fromComponentAlias,
      toComponentAlias,
      ids,
    })
    toggleRightPanel({ rightPanelVisible: true })
  }

  const handleConnectorTableSelection = ({
    componentId,
    ids,
    imageName,
    alias,
  }) => {
    dispatch({ type: 'table_connector_selected', componentId, ids, imageName })
    dispatch({
      type: 'block_diagram_connector_selected',
      componentId,
      ids,
      imageName,
      alias,
    })
  }
  const handleDeviceTableSelection = ({ componentId, ids, alias }) => {
    dispatch({ type: 'table_device_selected', componentId, ids })
    dispatch({ type: 'block_diagram_device_selected', componentId, ids, alias })
  }

  const reset = () => {
    handleRelatedSubsystemsButton({ subSystemButtonId: null })
    dispatch({ type: 'block_diagram_reset' })
  }
  const resetTable = () => {
    dispatch({ type: 'table_reset' })
  }

  // Detail Diagram
  const handlePinSelection = ({
    ids,
    pin,
    componentId,
    neighborIds,
    imageName,
    locationImageName,
    componentDescription,
    componentAlias,
    category,
  }) => {
    if (diagram.detailDiagram.relatedSubsystemComponentID !== componentId) {
      handleRelatedSubsystemsButton({
        relatedSubsystemComponentID: null,
      })
    }

    dispatch({
      type: 'detail_diagram_pin_selected',
      pin,
      componentId,
      ids,
      neighborIds,
      imageName,
      locationImageName,
      componentDescription,
      componentAlias,
      category,
    })
  }

  const handleCircuitSelection = ({
    ids,
    fromPin,
    toPin,
    fromComponent,
    toComponent,
    harness,
    harness_description,
    colors,
    circuit,
    circuit_description,
    fromComponentImageName,
    toComponentImageName,
    toComponentCategory,
    fromComponentCategory,
    fromLocationImageName,
    toLocationImageName,
    fromComponentDescription,
    toComponentDescription,
    fromComponentAlias,
    toComponentAlias,
  }) => {
    resetComponentNeighborTable()

    if (
      diagram.detailDiagram.relatedSubsystemComponentID !== toComponent &&
      diagram.detailDiagram.relatedSubsystemComponentID !== fromComponent
    ) {
      handleRelatedSubsystemsButton({
        relatedSubsystemComponentID: null,
      })
    }

    dispatch({
      type: 'detail_diagram_circuit_selected',
      ids,
      fromPin,
      toPin,
      fromComponent,
      fromComponentImageName,
      toComponent,
      toComponentImageName,
      harness,
      harness_description,
      colors,
      circuit,
      circuit_description,
      toComponentCategory,
      fromComponentCategory,
      fromLocationImageName,
      toLocationImageName,
      fromComponentDescription,
      toComponentDescription,
      fromComponentAlias,
      toComponentAlias,
    })
  }

  const handleComponentSelection = ({
    ids,
    componentId,
    alias,
    description,
    locationImageName,
    category,
  }) => {
    resetComponentNeighborTable()
    dispatch({
      type: 'detail_diagram_component_selected',
      ids,
      componentId,
      alias,
      description,
      locationImageName,
      category,
    })
  }

  const handleComponentNeighborSelection = ({
    componentId,
    imageName,
    locationImageName,
    pin,
    componentDescription,
    componentAlias,
    category,
  }) => {
    if (diagram.detailDiagram.relatedSubsystemComponentID !== componentId) {
      handleRelatedSubsystemsButton({
        relatedSubsystemComponentID: null,
      })
    }
    dispatch({
      type: 'detail_diagram_component_neighbor_selected',
      componentId,
      imageName,
      locationImageName,
      pin,
      componentDescription,
      componentAlias,
      category,
    })
  }

  const handleComponentNeighborTablePageSelection = ({ currentPage }) => {
    dispatch({
      type: 'detail_diagram_component_neighbor_table_page_selected',
      currentPage,
    })
  }

  const resetComponentNeighborTable = () => {
    dispatch({
      type: 'detail_diagram_component_neighbor_table_reset',
    })
  }

  const handleSubsystemSelection = ({ subsystem }) => {
    dispatch({
      type: 'subsystem_selected',
      subsystem,
    })
    setRecentSubsystemId({ subsystemId: subsystem.id })
  }

  const handleDiagramReset = () => {
    dispatch({
      type: 'reset',
    })
  }

  const handleMakeModelIDSelection = ({ makeModelID }) => {
    dispatch({
      type: 'sales_codes_make_model_id_selected',
      makeModelID,
    })
  }
  const handleSalesCodeAdded = ({ group, salesCode }) => {
    dispatch({
      type: 'sales_codes_sales_code_added',
      salesCode,
      group,
    })
  }

  const handleVinAdded = ({ vin }) => {
    dispatch({
      type: 'vin_added',
      vin,
    })
  }

  const handleInitialSalesCodes = ({ salesCodesSelection }) => {
    dispatch({
      type: 'sales_codes_initial',
      salesCodesSelection,
    })
  }

  const handleRelatedSubsystemsButton = ({ relatedSubsystemComponentID }) => {
    dispatch({
      type: 'related_subsystem_component_id_added',
      relatedSubsystemComponentID,
    })
  }

  const toggleRightPanel = ({ rightPanelVisible }) => {
    dispatch({
      type: 'toggle_right_panel',
      rightPanelVisible,
    })
  }

  return {
    toggleRightPanel,
    handleRelatedSubsystemsButton,
    handleSubsystemSelection,
    handleDiagramReset,
    handleVinAdded,
    blockDiagram: {
      handleTabChange,
      handleDeviceSelection,
      handleConnectorSelection,
      handleConnectionSelection,
      handleConnectorTableSelection,
      handleDeviceTableSelection,
      reset,
      resetTable,
    },
    detailDiagram: {
      handlePinSelection,
      handleCircuitSelection,
      handleComponentSelection,
      handleComponentNeighborSelection,
      handleComponentNeighborTablePageSelection,
      resetComponentNeighborTable,
    },
    salesCodes: {
      handleMakeModelIDSelection,
      handleSalesCodeAdded,
      handleInitialSalesCodes,
    },
  }
}

function diagramReducer(draft, action) {
  switch (action.type) {
    case 'reset': {
      draft.subsystem = initialDiagram.subsystem
      draft.blockDiagram = initialDiagram.blockDiagram
      draft.detailDiagram = initialDiagram.detailDiagram
      draft.rightPanelVisible = initialDiagram.rightPanelVisible
      break
    }
    case 'subsystem_selected': {
      draft.subsystem = action.subsystem
      break
    }

    case 'block_diagram_reset': {
      draft.blockDiagram = initialDiagram.blockDiagram
      break
    }

    case 'table_reset': {
      draft.blockDiagram.table = initialDiagram.blockDiagram.table
      break
    }

    case 'block_diagram_tab_selected': {
      draft.blockDiagram.tab = action.tab
      break
    }

    case 'table_connector_selected': {
      draft.blockDiagram.table.selection = 'connector'
      draft.blockDiagram.table.data = {
        componentId: action.componentId,
        ids: action.ids,
        imageName: action.imageName,
      }
      break
    }

    case 'table_device_selected': {
      draft.blockDiagram.table.selection = 'device'
      draft.blockDiagram.table.data = {
        componentId: action.componentId,
        ids: action.ids,
      }
      break
    }

    case 'block_diagram_device_selected': {
      draft.blockDiagram.graph.selection = 'device'
      draft.blockDiagram.graph.data = {
        componentId: action.componentId,
        ids: action.ids,
        neighborIds: action.neighborIds,
        imageName: action.imageName,
        description: action.description,
        alias: action.alias,
        locationImageName: action.locationImageName,
        category: action.category,
      }
      break
    }
    case 'block_diagram_connector_selected': {
      draft.blockDiagram.graph.selection = 'connector'
      draft.blockDiagram.graph.data = {
        componentId: action.componentId,
        ids: action.ids,
        imageName: action.imageName,
        description: action.description,
        color: action.color,
        alias: action.alias,
        neighborIds: action.neighborIds,
        locationImageName: action.locationImageName,
      }
      break
    }
    case 'block_diagram_connection_selected': {
      draft.blockDiagram.graph.selection = 'connection'
      draft.blockDiagram.graph.data = {
        fromComponent: action.fromComponent,
        toComponent: action.toComponent,
        fromComponentAlias: action.fromComponentAlias,
        toComponentAlias: action.toComponentAlias,
        ids: action.ids,
      }
      draft.detailDiagram = initialDiagram.detailDiagram
      break
    }
    case 'detail_diagram_pin_selected': {
      draft.detailDiagram.selection = 'pin'
      draft.detailDiagram.data = {
        pin: action.pin,
        ids: action.ids,
        componentId: action.componentId,
        neighborIds: action.neighborIds,
        imageName: action.imageName,
        locationImageName: action.locationImageName,
        componentDescription: action.componentDescription,
        componentAlias: action.componentAlias,
        category: action.category,
      }
      break
    }
    case 'detail_diagram_component_selected': {
      draft.detailDiagram.selection = 'component'
      draft.detailDiagram.data = {
        ids: action.ids,
        componentId: action.componentId,
        alias: action.alias,
        description: action.description,
        locationImageName: action.locationImageName,
        category: action.category,
      }

      break
    }
    case 'detail_diagram_component_neighbor_table_reset': {
      draft.detailDiagram.componentNeighbor =
        initialDiagram.detailDiagram.componentNeighbor
      break
    }
    case 'detail_diagram_circuit_selected': {
      draft.detailDiagram.selection = 'circuit'
      draft.detailDiagram.data = {
        fromComponent: action.fromComponent,
        fromComponentImageName: action.fromComponentImageName,
        toComponent: action.toComponent,
        toComponentImageName: action.toComponentImageName,
        fromPin: action.fromPin,
        toPin: action.toPin,
        ids: action.ids,
        harness: action.harness,
        harness_description: action.harness_description,
        colors: action.colors,
        circuit: action.circuit,
        circuit_description: action.circuit_description,
        toComponentCategory: action.toComponentCategory,
        fromComponentCategory: action.fromComponentCategory,
        fromLocationImageName: action.fromLocationImageName,
        toLocationImageName: action.toLocationImageName,
        fromComponentDescription: action.fromComponentDescription,
        toComponentDescription: action.toComponentDescription,
        fromComponentAlias: action.fromComponentAlias,
        toComponentAlias: action.toComponentAlias,
      }
      break
    }
    case 'detail_diagram_component_neighbor_selected': {
      draft.detailDiagram.componentNeighbor.data = {
        componentId: action.componentId,
        imageName: action.imageName,
        locationImageName: action.locationImageName,
        pin: action.pin,
        componentDescription: action.componentDescription,
        componentAlias: action.componentAlias,
      }
      break
    }
    case 'detail_diagram_component_neighbor_table_page_selected': {
      draft.detailDiagram.componentNeighbor.table.currentPage =
        action.currentPage
      break
    }
    case 'sales_codes_make_model_id_selected': {
      draft.salesCodes.makeModelID = action.makeModelID
      draft.salesCodes.salesCodesSelection =
        initialDiagram.salesCodes.salesCodesSelection
      break
    }
    case 'related_subsystem_component_id_added': {
      draft.detailDiagram.relatedSubsystemComponentID =
        action.relatedSubsystemComponentID
      break
    }
    case 'sales_codes_sales_code_added': {
      draft.salesCodes.salesCodesSelection[action.group] = action.salesCode
      break
    }
    case 'vin_added': {
      draft.vin = action.vin
      break
    }
    case 'sales_codes_initial': {
      draft.salesCodes.salesCodesSelection = action.salesCodesSelection
      break
    }
    case 'toggle_right_panel': {
      draft.rightPanelVisible = action.rightPanelVisible
      break
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialDiagram = {
  rightPanelVisible: true,
  salesCodes: {
    makeModelID: null,
    salesCodesSelection: {}, // group / sales code mapping
  },
  vin: null,
  subsystem: null,
  blockDiagram: {
    tab: 'device', // device, component
    table: {
      selection: null, // device, component, connection
      data: {
        componentId: null,
        ids: [], // Used to select all related elements. TODO: check if needed.
      },
    },
    graph: {
      selection: null, // device, component, connection
      data: {
        componentId: null,
        ids: [], // Used for graph to select all related elements.
        color: null,
        description: null,
        alias: null,
        neighborIds: [],
        fromComponent: null,
        toComponent: null,
        category: null,
      },
    },
  },
  detailDiagram: {
    relatedSubsystemComponentID: null,
    selection: null, // pin, circuit, component
    data: {
      componentId: null,
      pin: null,
      ids: [],
      fromPin: null,
      toPin: null,
      harness: null,
      category: null,
    },
    componentNeighbor: {
      data: {
        componentId: null,
        imageName: null,
        locationImageName: null,
        pin: null,
      },
      table: {
        currentPage: 1,
      },
    },
  },
}
